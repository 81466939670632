import React from 'react'
import Subscribe from '../components/subscribe'

import Layout from '../components/layout'

const SignUpPage = () => (
  <Layout>
    <div className="about">
      <div className="wrap">
        <h2>Мы хотим, чтобы ваш бизнес развивался благодаря нашим инструментам для создания долгосрочных отношений со своими клиентами.</h2>
      </div>
    </div>
    <Subscribe></Subscribe>
  </Layout>
)

export default SignUpPage
