import React from 'react'
import { Link } from 'gatsby'
import styles from "../styles/header.module.css"
import logo from '../images/logo.svg';

const Header = ({ siteTitle }) => (
  <div className={styles.head}>
    <Link
      to="/"
      className={styles.logo}
    >
      <img src={logo} width={141} height={34} alt={siteTitle} />
    </Link>

    <nav className={styles.nav}>
      <Link className={styles.item} to="/solutions" activeClassName={styles.active}>
        Решения
      </Link>
      <Link className={styles.item} to="/prices" activeClassName={styles.active}>
        Тарифы
      </Link>
      <Link className={styles.itemPrimary} to="/signup" activeClassName={styles.active}>
        Попробовать <span className={styles.hiddenXs}>первым</span>
      </Link>
    </nav>
  </div>
)

export default Header
