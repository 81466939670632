import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styles from "../styles/subscribe.module.css"

export default class Subscribe extends React.Component {
  constructor(props) {
     super(props);
     this.textInput = React.createRef();
  }
  state = {
    email: null,
    msg: "",
    showForm: true
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
    this.setState({ msg: ""});
  }

  _handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(this.state.email)
    .then(({msg, result}) => {
      if (result === 'success') {
        this.setState({ showForm: false });
      } else {
        this.textInput.current.focus();
        this.setState({ msg: msg});
      }

      if(this.state.email == null) {
        this.setState({ msg: ""});
      };
      if(this.state.msg === "The email you entered is not valid.") {
        this.setState({ msg: "Мы не сможем отправить письмо на этот адрес, проверьте опечатки"});
      }
      if(this.state.msg === "0 - This email address looks fake or invalid. Please enter a real email address.") {
        this.setState({ msg: "Email не похож на настоящий, письмо не дойдёт"});
      }

      console.log(result);

    })
    .catch(err => {
      this.textInput.current.focus();
    });
  }

  render() {
    return (
      <div className={styles.form}>
        {this.state.showForm ? <form onSubmit={this._handleSubmit} noValidate="novalidate">
          <h2 className={styles.title}>Ранний доступ к платформе</h2>
          <input type="email" onChange={this._handleChange} placeholder="Email" name="email" className={styles.field} ref={this.textInput} />
          {this.state.msg && <div className={styles.msg}>{this.state.msg}</div>}
          <input type="submit" value="Запросить доступ" className={styles.button} />
        </form> : <div><h3>Спасибо, что вы с нами!</h3><p>В один секретный день вы получите приглашение на почту {this.state.email}</p></div>}
      </div>
    )
  }
}
