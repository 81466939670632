import React from 'react'
import { Link } from 'gatsby'
import styles from "../styles/footer.module.css"
import logo from '../images/icon.svg';

const Footer = ({ siteTitle }) => (
  <div className={styles.footer}>
    <Link
      to="/"
      className={styles.logo}
    >
      <img src={logo} width={25} height={25} alt={siteTitle} />
      &copy; 2019 LoyaltyPro
    </Link>
    <Link to="/legal">Пользовательское соглашение и политика конфиденциальности</Link>
  </div>

)

export default Footer
